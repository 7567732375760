@tailwind base;
@tailwind components;
@tailwind utilities;

body, html {
  height: 100%;
}

#root {
  height: 100%;
  background: #1F2937;
}

.placeholder::placeholder {
  color: rgba(255, 255, 255, 0.1);
}

.input-container {
  height: 100px;
}

.responses-container {
  height: calc(100% - 100px);
}
